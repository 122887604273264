<template>
	<div class="com-land-list">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-space>
				<com-shop-search-select v-if="isShop == 0" @change="e=>search.shop_id = e"></com-shop-search-select>
				<a-input-search v-model:value="search.key"
                                placeholder="土地名称"
                                enter-button
                                style="width: 300px;"
                                @search="getLandList(1, landState.info.limit)" />
				<router-link to="/land/landEdit">
					<a-button type="primary" v-has="{action:'/land/landEdit',plat:isShop}" v-if="isShop==0">
						<i class="ri-add-line ri-top"></i>新增土地
					</a-button>
				</router-link>

				<router-link to="/many_store/land/landEdit">
					<a-button type="primary" v-has="{action:'/land/landEdit',plat:isShop}" v-if="isShop!=0">
						<i class="ri-add-line ri-top"></i>新增土地
					</a-button>
				</router-link>
			</a-space>
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table :data-source="landState.info.list" rowKey="id" size="small" :pagination="false"
				:columns="[
					{dataIndex: 'id', title: '土地信息', slots: { customRender: 'id' }},
					{dataIndex: 'price', title: '价格', slots: { customRender: 'price' }},
					{dataIndex: 'status',title: '出租/空闲', slots: { customRender: 'status' }},
					{dataIndex: 'is_put_away',title: '状态', slots: { customRender: 'is_put_away' }},
					{dataIndex: 'rank',title: '排序', slots: { customRender: 'rank' }},
					{dataIndex: 'action',title: '操作', slots: { customRender: 'action' },width:200},
				]" >
				<template #id="{record}">
					<div style="color: #333;font-size: 16px;">{{record.land_name}}</div>
					<div style="color: #999;">土地面积：{{record.fre_spec_area}}㎡</div>
					<div style="color: #999;">ID：{{record.id}}</div>
				</template>
				<template #price="{record}">
					{{record.min_price}}~{{record.max_price}}元
				</template>
				<template #status="{record}">
					<div style="display: flex;align-items: center;width: 170px;">
						<a-progress :percent="landPercent(record)" :show-info="false" size="small" strokeColor="#52c41a"/>
						<span style="margin-left: 10px;">{{record.use_spec_count+'/'+record.fre_spec_count}}</span>
					</div>
				</template>
				<template #is_put_away="{record}">
					<a-tag :color="record.is_put_away ==1 ?'#00CC66':'#999'">
                        {{record.is_put_away == 1?'已上架':'已下架'}}
                    </a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<router-link :to="{path:'/land/landEdit',query:{id:record.id,shop_id:record.shop_id}}">
							<kd-button icon="ri-edit-2-line"
								title="编辑"
								v-has="{action:'/land/landEdit',plat:isShop}" v-if="isShop == 0">
							</kd-button>
						</router-link>

						<router-link :to="{path:'/many_store/land/landEdit',query:{id:record.id,shop_id:record.shop_id}}">
							<kd-button icon="ri-edit-2-line"
								title="编辑" v-if="isShop !=0">
							</kd-button>
						</router-link>

						<kd-button icon="ri-delete-bin-5-line" title="删除"
							v-has="{action:'wxapp_land_del',plat:isShop}"
							@change="deleteLand([record.id])" type="danger">
						</kd-button>
						<kd-button title="小程序码"
                                   icon="ri-qr-code-line"
                                   type="success"
                                   @change="showLandQrcode(record.id)">
                        </kd-button>
						<kd-button title="导出土地二维码"
                                   icon="ri-download-line"
                                   @change="downloadQrcode(record.id)"
                                   type="purple">
                        </kd-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
				show-size-changer
				:default-current="landState.info.page"
				:total="landState.info.count"
				@showSizeChange="(page,e)=>{ getLandList(landState.info.page, e); }"
				@change="e=>{getLandList(e, landState.info.limit);}"/>
			</div>
		</div>
		<a-modal title="土地小程序码"
                 v-model:visible="lrState.show"
                 width="300px"
                 :footer="null"
                 @cancel="lrState.show=false">
			<div style="width: 100%;text-align: center;">
				<p style="color: #999;font-size: 12px;" v-if="!lrState.show">请重新编辑并保存土地信息以获取小程序码！</p>
				<a-image style="width: 200px; height: 200px" :src="lrState.qrcode"></a-image>
			</div>
		</a-modal>
	</div>
</template>

<script>
import comShopSearchSelect from '@/components/miniapp/com-shop-search-select.vue'
import { onActivated, reactive} from 'vue'
import { useLandList,useLandQrcode } from '@/models/land'

export default{
	name:'page-land-list',
	components:{
		comShopSearchSelect
	},
	props: {
		isShop: {
			type: Number
		}
	},
	setup(){
	    let search = reactive({
            key:"",
            shop_id:0
        })

        let { landState,getLandList,landPercent,deleteLand } = useLandList(search)

        //土地二维码展示
        let lrState = useLandQrcode()

        getLandList(1,10)

		onActivated(()=>{
			getLandList(landState.info.page,landState.info.limit)
		})
		return{
			search,
			deleteLand,
            landState,
            getLandList,
            landPercent,
            ...lrState ,
        }
	},
}
</script>
<style>
</style>
