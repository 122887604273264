<template>
	<!-- 店铺查询 -->
	<div>
		<a-select
			show-search
			v-model:value="shop_id"
			:defaultActiveFirstOption="false"
			placeholder="搜索店铺信息查询"
			style="width: 200px"
			:show-arrow="false"
			:filter-option="false"
			:not-found-content="null"
			@search="getStoreList"
			@change="getShopId"
		>
			<a-select-option :value="0" v-if="storeList.length > 0">搜索店铺信息查询</a-select-option>
			<a-select-option v-for="(item, index) in storeList" :key="index" :value="item.shop_id">
				{{ item.name }}
			</a-select-option>
		</a-select>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import shopModel from '@/api/addons/shop';
export default{
	name:'com-shop-search-select',
	setup(props,context){
		const state = reactive({
			shop_id:null,		//选中的shop_id
			storeList:[],
			timeout:null
		})

		function getStoreList(e){
			if( !e ) return
			fetchStore(e,res=>state.storeList = res)
		}

		//获取商户信息，使用settimeout节流
		function fetchStore(value,callback){
			if( state.timeout ){
				clearTimeout(state.timeout)
				state.timeout = null
			}

			function getStore(key){
				shopModel.getShop(1,999,{key},res=>callback(res.list))
			}
			state.timeout = setTimeout(function(){
				getStore(value)
			},500)
		}
		function getShopId(e){
			context.emit("change",e)
		}
		return{
			...toRefs(state),
			getStoreList,
			getShopId
		}
	}
}
</script>

<style>
</style>
