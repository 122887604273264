import {computed, reactive, toRaw} from "vue";
import landModel from "@/api/land";
import set from "@/api/set";
import siteinfo from "@/util/global";
import common from "@/api/common";
import tool from "@/util/tool";


/**
 * 土地列表
 * @param state
 * @returns {}
 */
export function useLandList(search){
    let landState = reactive({
        info:{
            list:[],
            page:1,
            limit:10,
            count:0
        }
    })

    function getLandList(page,limit){
        landModel.getLandList(page,limit,search,res=> landState.info = {limit,...res})
    }
    //地块出租与空闲比
    let landPercent = computed(()=>{
        return function (data){
            let _p = parseFloat((data.use_spec_count / data.fre_spec_count) * 100).toFixed(2)
            return parseFloat(_p)
        }
    })

    function deleteLand(id){
        landModel.deleteLand(id,()=>{
            getLandList(landState.info.page,landState.info.limit)
        })
    }

    return { landState ,getLandList,landPercent,deleteLand }
}

//土地二维码
export function useLandQrcode(){
    let lrState = reactive({
        show:false,
        qrcode:""
    })

    function showLandQrcode(id){
        let path = `/pages/land/land_detail?land_id=${id}`
        set.getQrcode(path,'wx_app').then(res=>{
            lrState.show = true
            lrState.qrcode = res
        })
    }

    function downloadQrcode(id){
        let _host = siteinfo.siteinfo.siteroot , _t= localStorage.getItem('token')
        let url = `${_host}/api/admin/landSpecQrcode?token=${_t}&land_id=${id}`
        window.open(url);
    }

    return{ lrState ,showLandQrcode,downloadQrcode }
}

/**
 * 土地分类添加、编辑、删除操作
 * @param state
 * @param fn
 * @returns
 */
export function useLandCategory( state,fn ){
    let lcState = reactive({
        show:false,
        form:null
    })

    function showEditLandCategory(data){
        lcState.form = {
            id:data ? data.id : 0,
            name:data ? data.name :"",
            rank:data ? data.rank :99,
        }
        lcState.show = true
    }

    function saveLandCategory(){
        landModel.addOrEditCategory( toRaw( lcState.form ) ,()=>{
            lcState.show = false
            if( typeof fn == 'function' )fn(state.info.page,state.info.limit)
        })
    }

    function deleteLandCategory(id){
        common.deleteDataList(id,2,'确认删除该土地分类吗?').then(()=>{
            if( typeof fn == 'function' ) fn(state.info.page,state.info.limit)
        })
    }

    return{ lcState,showEditLandCategory,saveLandCategory ,deleteLandCategory}
}

/**
 * 土地种子列表 、删除功能
 * @param search    查询参数
 * @returns {}
 */
export function useSeedList(search){
    let slState = reactive({
        list:[],
        page:1,
        count:0,
        limit:10
    })

    function getSeed(page,limit){
        landModel.getSeed(page,limit,search,res=>{
            slState.list = res.list
            slState.count = res.count
        })
    }
    return{ slState,getSeed}
}

/**
 * 土地种子状态修改
 * @param state 列表数据信息
 * @param fn    刷新函数
 * @returns {}
 */
export function useSeedStatus(state,fn){

    function refreshPage(){
        if( typeof fn == 'function' ) fn(state.page,state.limit)
    }

    const deleteSeed=id=>landModel.deleteSeed(id,()=>refreshPage())
    const setSeedHot=id=>landModel.setSeetHot(id,()=>refreshPage())

    return { deleteSeed,setSeedHot }
}


/**
 * 新增/编辑、删除种子分类
 * @param fn 重新获取列表数据方法
 * @returns {}
 */
export function useEditSeedCategory(fn){
    let escState = reactive({
        show:false,
        form:null
    })

    function showSeedCategoryEdit(data){
        escState.form = {
            id:data ? data.id :0,
            name:data ? data.name:'',
            rank:data ? data.rank:99,
            image:data ? data.image:""
        }
        escState.show = true
    }

    function saveSeedCategory(){
        landModel.addOrEditSeedType(toRaw(escState.form),()=>{
            if(typeof fn == 'function' )fn(1,999)
            escState.show = false
        })
    }
    const deleteSeedCategory = id=>common.deleteDataList(id,58,'确认删除该种子分类吗?').then(()=>fn(1,999))

    return{ escState,showSeedCategoryEdit,saveSeedCategory ,deleteSeedCategory}
}

/**
 * 种植状态跟踪信息操作
 * @param state
 *        ids
 *        landId:地块id
 * @returns {}
 */
export function usePlantStatusTail(state){
    let pstState = reactive({
        show:false,
        form:null,
        showList:false,
        list:[],
    })

    function showAddOrEdit(info){
        let _d = toRaw(info)
        let form = {
            id:_d?_d.id:0,
            maturity:_d ? _d.maturity : null,
            yield:_d ? _d.yield : "",
            src:_d ? _d.src_show : [],
            description:_d ? _d.description : "",
            server_content:_d && _d.server_content ? _d.server_content : [],
            plant_id:_d ? _d.plant_id : 0,
        }

        landModel.getLandServerById(state.landId,res=>{
            res.forEach(item=>{
                form.server_content.push({
                    server_id:item.id,
                    name:item.title,
                    is_need:0,		//0 不需要要 1需要
                })
            })
            pstState.form = form
            pstState.show = true
        })
    }

    function savePlantStatus(){
        let form = toRaw( pstState.form ) ,ids = []
        if( state.ids && typeof state.ids == 'object' ){
            if( state.ids.length == 0 ){
                tool.message("暂时没有种植信息需要添加状态",'warning')
                return
            }

            if( typeof state.ids[0] == 'object' ){
                state.ids.forEach(item=>ids.push(item.id))
                form.plant_id = ids
            }
        }

        form.plant_id = ids.length >0 ? ids :state.ids

        if( !form.id ) delete form.id
        landModel.addOrEditPlantStatus(form,()=>{
            pstState.show = false

            //仅为添加模式时，添加完成不需要展示列表数据
            if( state.scene == 'all') showPlantStateList()
        })
    }

    function showPlantStateList(){
        pstState.showList = true
        landModel.getPlantStatus(state.ids,res=>pstState.list = res)
    }

    let deletePlantStatus = data =>{
        common.deleteDataList(data.id,32,'确认删除该条信息吗').then(()=>showPlantStateList() )
    }

    return{ pstState,showAddOrEdit,savePlantStatus ,showPlantStateList,deletePlantStatus}
}


/**
 * 种植订单
 * @param state
 * @param search    查询参数
 * @returns {}
 */
export function usePlantOrder(state,search){
    let poState = reactive({
        list:[],
        page:1,
        limit:10,
        count:0
    })

    function refreshPage(){
        getPlantOrderList(poState.page,poState.limit)
    }

    function getPlantOrderList(page,limit){
        landModel.getPlantOrder(page,limit,search,res=>{
            poState.list = res.list
            poState.count = res.count
            poState.limit = limit
        })
    }
    //退款
    let refundPlantOrder = id=> landModel.setPlantOrderStatus('refund_yes',id,()=>refreshPage() )

    //改变所有种植订单下面的种子的状态
    function changePlantOrderAllSeedStatus(data,op){
        let ids = []
        if( data.plants && data.plants.length > 0 ){
            data.plants.forEach( item=> ids.push(item.id) )
        }
        landModel.setPlantSeedStatus( op,ids,()=>refreshPage() )
    }


    return { poState,getPlantOrderList,refundPlantOrder , changePlantOrderAllSeedStatus}
}


//手动添加种植订单
export function usePlantOrderAddSeed(){
    let poaState = reactive({
        show:false,
        form:{
            order_number:"",
            plant_time:"",
            checkSeed:[],
            seed:[],
            seedList:[],
        }
    })

    function getCanPlantSeed(){
        let { order_number } = poaState.form
        if( !order_number ) return
        landModel.getCanPlantSeed(1,999,{order_number},res=>{
            poaState.form.seedList = res.list
        })
    }

    function getSelectSeed(e){
        let seed = [] , arrId = [] , oldSeed = JSON.parse(JSON.stringify(poaState.form.seed))
        e.forEach(item=>{
            let temp = poaState.form.seedList.find(val=>val.id == item)
            seed.push({...temp,num:1})
        })

        //剔除不要的数据
        oldSeed.forEach((item,index)=>{
            e.includes(item.id) ? arrId.push(item.id) :poaState.form.seed.splice(index,1)
        })

        //追加需要的数据
        seed.forEach(item=>{
            if( !arrId.includes(item.id)) poaState.form.seed.push(item)
        })
    }

    function saveAddSeed(){
        landModel.addLandPlantOrder(poaState.form,()=>{
            poaState.show = false
            poaState.form = {
                order_number:"",
                seed:[],
                plant_time:"",
                checkSeed:[],
                seedList:[],
            }
        })
    }


    return{ poaState ,getCanPlantSeed,getSelectSeed,saveAddSeed }
}


/**
 * 续费订单
 * @param search
 * @returns {}
 */
export function useLandRenewOrder(search){
    let lroState = reactive({
        list:[],
        page:1,
        count:0,
        limit:10
    })

    function getLandRenewOrder(page,limit){
        landModel.getLandRenewOrder(1,999,search,res=>{
            lroState.list = res.list
            lroState.limit = 999
        })
    }

    //退款
    function refundLandRenewOrder(id){
        landModel.setLandOrderStatus('refund_yes',id,()=>{
            getLandRenewOrder(1,999)
        })
    }

    return{ lroState,getLandRenewOrder,refundLandRenewOrder }
}

/**
 * 土地订单状态修改
 * @param state
 * @param fn
 * @returns {}
 */
export function useLandOrder(state,fn){

    function refreshPage(){
        if( typeof fn != 'function') return
        state ? fn(state.info.page,state.info.limit) :fn()
    }

    /**
     * 土地订单操作
     * @param op confirm=终止租赁、refund_yes=退款、cancel=取消订单、refund_yes_order=土地订单退款
     * @param id
     */
    function changeLandOrderState(op,id){
        landModel.setLandOrderStatus(op,id,res=>refreshPage())
    }


    return {
        changeLandOrderState,
    }

}

/**
 * 土地订单服务修改操作
 * @param fn
 * @returns {}
 */
export function useEditLandOrderService(fn){
    let elosState = reactive({
        show:false,
        list:[],
        ser_id:null
    })

    function showEditService(){
        landModel.getLandServer(1,99,null,res=>{
            elosState.list = res.list
            elosState.show = true
        })
    }

    function saveLandOrderService(order_id){
        landModel.setLandOrder('change-server',order_id, {ser_id:elosState.ser_id},()=>{
            elosState.show = false
            if( typeof fn == 'function') fn()
        })
    }

    return { elosState,showEditService ,saveLandOrderService}
}

/**
 * 土地订单巡检员分配操作
 * @param fn
 * @returns {}
 */
export function useEditLandOrderPolling(fn){
    let elopState = reactive({
        show:false,
        list:[],
        inspector_id:null,
    })
    function showEditPolling(){
        landModel.getInspector(1,99,{type:1,status:1},res=>{
            elopState.list = res.list
            elopState.show = true
        })
    }

    function savePolling(order_id){
        landModel.setLandOrder('change-inspector',order_id,{user_id:elopState.inspector_id},res=>{
            elopState.show = false
            if( typeof fn == 'function') fn()
        })
    }

    return{ elopState,savePolling,showEditPolling}
}

/**
 * 土地订单修改过期时间
 * @param fn
 * @returns {}
 */
export function useEditExpireTime(fn){
    let eetState = reactive({
        show:false,
        expire_date:""
    })

    function saveExpireTime(order_id){
        let data = { expire_date : eetState.expire_date }
        landModel.setLandOrder('change-time',order_id,data,res=>{
            eetState.show = false
            if( typeof fn == 'function') fn()
        })
    }

    return {eetState ,saveExpireTime }
}

/**
 * 土地配送订单
 * @param search 查询参数
 * @param fn     页面刷新函数
 * @returns {}
 */
export function useLandDeliveryOrder(search,fn){
    let ldoState = reactive({
        list:[],
        page:1,
        limit:10,
        count:0
    })

    function getLandDeliveryOrderList(page,limit){
        landModel.getDeliveryOrder(page,limit,search,res=>{
            ldoState.list = res.list
            ldoState.page = res.page
            ldoState.count = res.count
            ldoState.limit = limit
        })
    }

    //改变配送订单状态
    function changeDeliveryOrderStatus(op,id){
        landModel.setLandDeliveryOrderStatus(op,id,()=>{
            if(typeof fn == 'function') fn()
        })
    }
    return{ ldoState ,getLandDeliveryOrderList ,changeDeliveryOrderStatus}
}

/**
 * 手动添加配送
 * @param fn
 * @returns {}
 */
export function useAddLandDeliveryOrder(fn){
    let aldoState = reactive({
        form:null,
        show:false,
    })

    function showAddLandDeliveryOrder(data){
        aldoState.form = {
            name:data.order.address.name,
            phone:data.order.address.phone,
            address:data.order.address.address,
            pre_delivery_time:null,
            delivery_weight:'',
            order_id:0,
            item_name:'',
            item_price:'',
            item_quantity:'',
            lat:'',
            lng:'',
        }
        aldoState.show = true
    }

    function saveLandDeliveryOrder(order_id){
        landModel.addDeliveryOrder(order_id,toRaw(aldoState.form) ,()=>{
            aldoState.show = false
            aldoState.form.pre_delivery_time = null
            aldoState.form.delivery_weight = ''
            if( typeof fn == 'function' )fn()
        })
    }

    return{ aldoState,showAddLandDeliveryOrder,saveLandDeliveryOrder }
}



















